import React from 'react';
import './LoadingScreen.scss';  // Asegúrate de crear este archivo CSS para tu animación

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-animation">
       
      </div>
    </div>
  );
};

export default LoadingScreen;
